import(/* webpackMode: "eager" */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/node_modules/@codegouvfr/react-dsfr/assets/dsfr_plus_icons.scss");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/apple-touch-icon.png");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.ico");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/node_modules/@codegouvfr/react-dsfr/dsfr/favicon/favicon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["DsfrProvider"] */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/node_modules/@codegouvfr/react-dsfr/next-appdir/DsfrProvider.js");
;
import(/* webpackMode: "eager" */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/website/src/app/[lang]/layoutCore.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/website/src/components_simple/StartDsfr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/home/bas/app_b87cda9f-4054-4354-b92f-82f57d04f7dd/website/src/i18n/I18n.tsx");
